// Web
// let http
// const env = process.env.VUE_APP_NAME_PREFIX
// if (env == 'prod') {
//         http = "https://"
// } else {
//         http = "https://" + process.env.VUE_APP_NAME_PREFIX + "-"
// }

export const http                           = "https://" // สำหรับ dev
export const head                           = http
export const homepage                       = head + "www.packhai.com/"
export const customerWebsite                = head + "customer.packhai.com/"
export const hostService                    = head + "service.packhai.com/"
export const packhaiApi88                   =".packhai-api-88.com/"

// Service : Python
export const adminService                   = hostService + "admin-service/" 
export const manageShopService              = hostService + "manage-shop-service/"
export const financialService               = hostService + "financial-service/" 
export const selectService                  = hostService + "select-service/"

// Service : .NET Core
export const reportService_dotnet           = head + "report" + packhaiApi88
export const shopService_dotnet             = head + "shop" + packhaiApi88
export const branchService_dotnet           = head + "branch" + packhaiApi88
export const stockService_dotnet            = head + "stock" + packhaiApi88 
export const orderService_dotnet            = head + "order" + packhaiApi88
export const generalService_dotnet          = head + "general" + packhaiApi88
export const logisticService_dotnet         = head + "logistic" + packhaiApi88
export const ecomService_dotnet             = head + "ecom" + packhaiApi88 

// export const stockService_dotnet            = "https://localhost:5001/"
// export const orderService_dotnet            = "https://localhost:5001/"
// 